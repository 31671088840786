import { NgModule, ErrorHandler } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy, RouterModule } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './services/Auth/authentication.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Intercept } from "./interceptors/interceptors";
import { TextMaskModule } from 'angular2-text-mask';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { environment, firebaseConfig } from '../environments/environment';

import { MapsProviderService } from './providers/maps-provider.service';
import { JsMapsProviderService } from './providers/js-maps-provider.service';
import { NativeMapsProviderService } from './providers/native-maps-provider.service';
import { GoogleMaps } from '@ionic-native/google-maps/ngx';
import { SignInWithApple } from '@awesome-cordova-plugins/sign-in-with-apple/ngx';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { AndroidPermissions } from '@ionic-native/android-permissions/ngx';
import { ComponentsModule } from './components/components.module';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { PubNubAngular } from "pubnub-angular2";
import { LocationAccuracy } from '@ionic-native/location-accuracy/ngx';
import { OpenNativeSettings } from '@awesome-cordova-plugins/open-native-settings/ngx';
import { ServiceWorkerModule } from "@angular/service-worker";
// import { FCM } from '@ionic-native/fcm/ngx';
import { SocialSharing } from '@awesome-cordova-plugins/social-sharing/ngx';

@NgModule({
  declarations: [AppComponent],
  imports: [
    RouterModule,
    BrowserModule, IonicModule.forRoot(), AppRoutingModule,
    HttpClientModule,
    TextMaskModule,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireAuthModule,
    ComponentsModule,
    BrowserAnimationsModule,
    ServiceWorkerModule.register("ngsw-worker.js", {
      enabled: environment.swVersionUpdate,
    }),
  ],
  providers: [
    // FCM,
    // FirebaseX,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: HTTP_INTERCEPTORS, useClass: Intercept, multi: true },
    AuthenticationService,
    MapsProviderService,
    JsMapsProviderService,
    NativeMapsProviderService,
    Geolocation,
    GoogleMaps,
    SignInWithApple,
    AndroidPermissions,
    LocationAccuracy,
    OpenNativeSettings,
    PubNubAngular,
    SocialSharing,
    // Facebook
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
