import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./Pages/feed/feed.module').then( m => m.FeedPageModule)
  },
  {
    path: 'world-map',
    loadChildren: () => import('./Pages/home/home.module').then( m => m.HomePageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./Pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'login/:impersonateUserToken',
    loadChildren: () => import('./Pages/login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'signup',
    loadChildren: () => import('./Pages/User-Account/signup/signup.module').then( m => m.SignupPageModule)
  },
  {
    path: 'user-location',
    loadChildren: () => import('./Pages/user-location/user-location.module').then( m => m.UserLocationPageModule)
  },
  {
    path: 'my-duckings',
    loadChildren: () => import('./Pages/my-duckings/my-duckings.module').then( m => m.MyDuckingsPageModule)
  },
  {
    path: 'leader-boards',
    loadChildren: () => import('./Pages/leader-boards/leader-boards.module').then( m => m.LeaderBoardsPageModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./Pages/my-account/my-account.module').then( m => m.MyAccountPageModule)
  },
  {
    path: 'my-duckings/got-ducked-details',
    loadChildren: () => import('./Pages/got-ducked-details/got-ducked-details.module').then( m => m.GotDuckedDetailsPageModule)
  },
  {
    path: 'my-duckings/ducked-someone-details',
    loadChildren: () => import('./Pages/ducked-someone-details/ducked-someone-details.module').then( m => m.DuckedSomeoneDetailsPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./Pages/User-Account/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'reset-password/:token',
    loadChildren: () => import('./Pages/User-Account/reset-password/reset-password.module').then( m => m.ResetPasswordPageModule)
  },
  {
    path: 'change-password',
    loadChildren: () => import('./Pages/User-Account/change-password/change-password.module').then( m => m.ChangePasswordPageModule)
  },
  {
    path: 'message-list',
    loadChildren: () => import('./Pages/Messages/message-list/message-list.module').then( m => m.MessageListPageModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('./Pages/Messages/message-details/message-details.module').then( m => m.MessageDetailsPageModule)
  },
  {
    path: 'messages/:handle',
    loadChildren: () => import('./Pages/Messages/message-details/message-details.module').then( m => m.MessageDetailsPageModule)
  },
  {
    path: 'products-list',
    loadChildren: () => import('./Pages/Shopping/list-products/list-products.module').then( m => m.ListProductsPageModule)
  },
  {
    path: 'product-details/:productId',
    loadChildren: () => import('./Pages/Shopping/view-products/view-products.module').then( m => m.ViewProductsPageModule)
  },
  {
    path: 'duckings/ducks-by-handle/:handle',
    loadChildren: () => import('./Pages/list-ducks-by-handle/list-ducks-by-handle.module').then( m => m.ListDucksByHandlePageModule)
  },
  {
    path: 'social-sharing/:id',
    loadChildren: () => import('./Pages/social-sharing/social-sharing.module').then( m => m.SocialSharingPageModule)
  },
  {
    path: 'support',
    loadChildren: () => import('./Pages/help/help.module').then( m => m.HelpPageModule)
  },
  {
    path: 'feed',
    loadChildren: () => import('./Pages/feed/feed.module').then( m => m.FeedPageModule)
  },
  {
    path: 'user-profile',
    loadChildren: () => import('./Pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  },
  {
    path: 'user-profile/:handle',
    loadChildren: () => import('./Pages/user-profile/user-profile.module').then( m => m.UserProfilePageModule)
  }




 











];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
