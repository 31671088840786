<ion-app>
  <ion-split-pane [when]="maxWidth" contentId="main">
    <ion-menu contentId="main" class="app-sidemenu" style="overflow:auto" side="start" swipe-gesture="false">
      <ion-header>
        <ion-toolbar color="dark">
          <ion-title>Happy Duckers</ion-title>
          <!-- <ion-button (click)="maxWidth = maxWidth=='xl'?'(min-width:7200px)':'xl'">
              <ion-icon name="grid"></ion-icon>
          </ion-button> -->
        </ion-toolbar>
      </ion-header>
      <ion-content class="ion-padding">

        <ion-list lines="none">
          <!-- <ion-menu-toggle auto-hide="false"> -->
          <ion-item routerLink="/home" routerLinkActive="activated">
            <ion-icon slot="start" name="arrow-up-circle-outline"></ion-icon>
            <ion-label>Home</ion-label>
          </ion-item>

          <ion-item routerLink="/world-map" routerLinkActive="activated">
            <ion-icon slot="start" name="map-outline"></ion-icon>
            <ion-label>Map</ion-label>
          </ion-item>

          <ion-item routerLink="/user-location" routerLinkActive="activated">
            <ion-icon slot="start" name="location-outline"></ion-icon>
            <ion-label>User Location</ion-label>
          </ion-item>

          <ion-item routerLink="/my-duckings" routerLinkActive="activated">
            <ion-icon slot="start" name="custom-duck-icon"></ion-icon>
            <ion-label>Duckings</ion-label>
          </ion-item>

          <ion-item routerLink="/leader-boards" routerLinkActive="activated">
            <ion-icon slot="start" name="list-outline"></ion-icon>
            <ion-label>Leadboards</ion-label>
          </ion-item>

          <ion-item routerLink="/messages" routerLinkActive="activated">
            <ion-icon slot="start" name="mail-open-outline"></ion-icon>
            <ion-label>Messages</ion-label>
          </ion-item>

          <ion-item *ngIf="your_appcomponent_member" routerLink="/products-list" routerLinkActive="activated">
            <ion-icon slot="start" name="pricetags-outline"></ion-icon>
            <ion-label>Shopping</ion-label>
          </ion-item>

          <ion-item (click)="logout()" routerLinkActive="activated">
            <ion-icon slot="start" name="log-out-outline"></ion-icon>
            <ion-label>Logout</ion-label>
          </ion-item>
          <!-- </ion-menu-toggle> -->
        </ion-list>

        <!-- <ion-list> -->
        <!-- <ion-menu-toggle auto-hide="false" > -->

        <!-- </ion-menu-toggle> -->
        <!-- </ion-list> -->
      </ion-content>
    </ion-menu>

    <!-- <div class="map-footer">
      <ul>
        <li>
          <a routerLink="/home" routerLinkActive="active">
            <ion-icon name="arrow-up-circle-outline"></ion-icon>
            <label>Home</label>
          </a>
        </li>
        <li>
          <a routerLink="/world-map" routerLinkActive="active">
            <ion-icon name="map-outline"></ion-icon>
            <label>Map</label>
          </a>
        </li>
        <li>
          <a routerLink="/my-duckings" routerLinkActive="active">
            <ion-icon name="custom-duck-icon"></ion-icon>
            <label>Duckings</label>
          </a>
        </li>
        <li>
          <a routerLink="/leader-boards" routerLinkActive="active">
            <ion-icon name="list-outline"></ion-icon>
            <label>Leaderboards</label>
          </a>
        </li>
        <li>
          <a routerLink="/messages" routerLinkActive="active">
            <ion-icon name="mail-open-outline"></ion-icon>
            <label>Messages</label>
          </a>
        </li>
        <li *ngIf="your_appcomponent_member">
          <a routerLink="/products-list" routerLinkActive="active">
            <ion-icon name="pricetags-outline"></ion-icon>
            <label>Shopping</label>
          </a>
        </li>
      </ul>
    </div> -->


    <ion-router-outlet id="main"></ion-router-outlet>

  </ion-split-pane>
</ion-app>