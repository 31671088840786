export const environment = {
  production: true,
  swVersionUpdate: true,
  socialSharingEnabled: false,
};

export const config = {
  hostName: 'https://happyduckers-production.herokuapp.com',
  envName: 'Production',
  baseUrl: 'https://happyduckers-production.web.app',
};

// matt add keys for production
export const firebaseConfig = {
  apiKey: "AIzaSyAvs2ry2d6aJGcGyesSqZfXojppZb5JkJc",
  authDomain: "happy-duckers.firebaseapp.com",
  projectId: "happy-duckers",
  storageBucket: "happy-duckers.appspot.com",
  messagingSenderId: "962413917269",
  appId: "1:962413917269:web:9cab687887339750be90dc",
  measurementId: "G-G7G1G2RHCM"
};

export const cloud = {
  cloudName: "ecommmgr", // these are ecom manager credentials for the cloudinary
  upload_preset: "pncxdqnx" // these are ecom manager credentials for the cloudinary
};

export const pubnubKeys = {
  pubNubPublishKey: "pub-c-38219893-3fd5-4b15-9893-98d95cfaa03b",
  pubNubSubscribeKey: "sub-c-06cc49d1-d843-47c4-a289-dafc05d2353a",
  channel: 'happy-duckers'
}

export const appleLogin = {
  redirectionUrl: 'https://happyduckers-production.web.app/login'
}